<template>
    <div class="server-status grid">
      medialist
    </div>
</template>

<script>

export default {
    name: "MediaList",
    components: {

    },
}
</script>